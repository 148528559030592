export const displayWarningRegistrationEmailExists = () => {
  const warning = document.querySelector('#warningEmailExists');

  if (!warning) return;

  document.addEventListener('registration:email:exists', () => {
    warning.classList.remove('is-hidden');
    document.dispatchEvent(new CustomEvent('Tracking:login', {
      detail: {
        type: 'security',
        data: {
          action: 'error',
          error_message: 'vous_avez_deja_un_compte',
        },
      },
    }));
  });
};
