import { splitUrls } from './utils/splitUrls';
import { getCookies, getCookieExpirationDate } from './utils/cookies';
import { getThematicsBySchoolLevel, mappingSchoolLevels } from './utils/mapping';

const COOKIES_USER = ['lumni_user_establishment', 'lumni_user_schoolLevel'];
const COOKIES_STUDENT = ['establishment', 'schoolLevel', 'thematic'];
const COOKIES_CONTAINERS = ['containerSlug', 'containerTitle', 'containerType'];

class Cookies {
  constructor(isUserConnected = false) {
    this.cookies = {};
    this.previousCookies = {};
    this.urlInfo = {};
    this.containerTypes = [];
    this.cookiesToCreate = [];
    this.cookieDaysDuration = 365;
    this.pageToResetCookies = null;
    this.useUserCookies = false;
    this.isUserConnected = isUserConnected;
  }

  init() {
    // Set variables for Student
    this.setContextVariables();

    // Keep values of cookies from the previous page
    this.previousCookies = getCookies(Cookies.getCookieNames());

    // Only use user cookies if the user comes from outside Lumni
    if (document.referrer.length === 0) {
      this.useUserCookies = this.isUserConnected && typeof this.previousCookies.lumni_user_schoolLevel !== 'undefined';
    }

    this.resetCookiesForDirectAccess();
    this.resetCookiesForContent();
    this.resetCookiesForUser();

    if (this.pageToResetCookies !== null) {
      this.resetCookies();
      this.createCookies();
    }

    if (this.urlInfo.isHomepage) {
      this.resetCookies();
    }

    return true;
  }

  setContextVariables() {
    this.containerTypes = COOKIES_CONTAINERS;
    this.cookiesToCreate = COOKIES_STUDENT.concat(this.containerTypes);
    this.urlInfo = splitUrls(window.location);
    this.pageToResetCookies = this.urlInfo.establishment;
  }

  resetCookiesForDirectAccess() {
    if (document.referrer.length === 0 && !this.useUserCookies) {
      // Clean the cookies
      this.resetCookies();

      if (this.urlInfo.isContentPage) {
        // Create cookies from the current item
        this.createCookies(true);
        this.previousCookies = getCookies(Cookies.getCookieNames());
      }
    }
  }

  resetCookiesForContent() {
    if (document.referrer.length === 0 && !this.useUserCookies) return;

    if (this.urlInfo.isContentPage) {
      if (!window.isPageAnnex || !window.isPageGamificationLanding) {
        this.checkContextFromPreviousPage();
      }

      // And Clean the previous cookies
      this.previousCookies = getCookies(Cookies.getCookieNames());
    }
  }

  resetCookiesForUser() {
    if (this.useUserCookies && this.urlInfo.isUserPage) {
      this.createCookies(true, ['establishment'], this.getEstablishment());
      this.createCookies(true, ['schoolLevel'], this.getSchoolLevel());

      this.setCookies();
      this.previousCookies = getCookies(Cookies.getCookieNames());
    }
  }

  checkContextFromPreviousPage() {
    const schoolLevel = this.isSameEstablishment();

    // If cookie schoolLevel does not match with item,
    // we clean actual cookies and recreate them from the current item
    if (schoolLevel === false && !window.isPageGamificationLanding) {
      this.resetCookies();
      this.createCookies(true);

      return;
    }

    // If user is connected and his cookies match item qualifications,
    // we can create cookies based on user preferences
    if (this.useUserCookies) {
      this.createCookies(true, ['establishment'], this.getEstablishment());
      this.createCookies(true, ['schoolLevel'], schoolLevel);
      this.createCookieThematic();

      return;
    }

    // Else we create cookies based on the previous page visited with "previousCookies"

    if (typeof this.previousCookies.schoolLevel === 'undefined') {
      this.createCookies(true, ['schoolLevel'], schoolLevel);
    }

    if (typeof this.previousCookies.thematic === 'undefined') {
      this.createCookieThematic();
      return;
    }

    // if cookie thematic exists but does not match with the item,
    // we recreate it with the thematic of the item for the current school level
    if (!this.isSameThematic()) {
      this.resetCookies(this.cookiesToCreate.slice(2));
      this.createCookieThematic();
    }
  }

  isSameEstablishment() {
    const cookieEstablishment = this.getEstablishment();

    if (typeof cookieEstablishment === 'undefined') {
      return false;
    }

    // Get school levels of the establishment from cookie
    const establishmentSchoolLevels = mappingSchoolLevels[cookieEstablishment];
    // Get school levels of the current item
    const itemSchoolLevels = this.getValueForCookie('schoollevels', true).split(',');
    // Get school level from cookies
    const cookieSchoolLevel = this.getSchoolLevel();

    if (typeof cookieSchoolLevel !== 'undefined') {
      return itemSchoolLevels.includes(cookieSchoolLevel)
      && establishmentSchoolLevels.includes(cookieSchoolLevel)
        ? cookieSchoolLevel
        : false;
    }

    let isSameEstablishment = false;

    itemSchoolLevels.forEach((element) => {
      if (establishmentSchoolLevels.includes(element) && !isSameEstablishment) {
        isSameEstablishment = element;
      }
    });

    return isSameEstablishment;
  }

  isSameThematic() {
    const thematicsOfSchoolLevel = this.getThematicsOfSchoolLevel();
    let isSameThematic = false;

    this.getItemThematics().forEach((element) => {
      if (element === this.previousCookies.thematic && thematicsOfSchoolLevel.includes(element)) {
        isSameThematic = true;
      }
    });

    return isSameThematic;
  }

  createCookieThematic() {
    const thematic = this.getThematicForCurrentSchoolLevel();

    if (thematic !== null) {
      this.createCookies(true, ['thematic'], thematic);
    }
  }

  getThematicForCurrentSchoolLevel() {
    const thematicsOfSchoolLevel = this.getThematicsOfSchoolLevel();
    let thematic = null;

    // Get the first thematic that matches the school level
    this.getItemThematics().forEach((element) => {
      if (thematicsOfSchoolLevel?.includes(element) && thematic === null) {
        thematic = element;
      }
    });

    return thematic;
  }

  resetCookies(cookiesToReset = null) {
    const cookies = cookiesToReset || this.cookiesToCreate;

    cookies.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      delete this.cookies[cookieName];
    });

    // Update values of current cookies
    this.setCookies();
    this.previousCookies = getCookies(Cookies.getCookieNames());
  }

  createCookies(takeValueFromItem = false, cookiesToCreate = null, overrideValue = null) {
    const cookies = cookiesToCreate || this.cookiesToCreate;

    cookies.forEach((cookie) => {
      const valueForCookie = overrideValue || this.getValueForCookie(cookie, takeValueFromItem);

      if (valueForCookie?.length > 0) {
        const secureCookie = (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') ? '' : 'secure';
        document.cookie = `${cookie}=${valueForCookie}; expires=${getCookieExpirationDate(this.cookieDaysDuration)}; path=/; ${secureCookie}`;
      }
    });

    // Update values of current cookies
    this.setCookies();
    this.previousCookies = getCookies(Cookies.getCookieNames());
  }

  getValueForCookie(cookieName, takeValueFromItem) {
    if (takeValueFromItem) {
      // Get data attributes from the .custom-theme
      const dataset = Cookies.getDataset();
      let attributeName = Cookies.getAttributeName(cookieName);
      attributeName = document.querySelector('.custom-theme')
        ? attributeName.toLowerCase()
        : attributeName;

      // Only add the thematic for the current school level to prevent errors of qualification
      if (attributeName === 'thematic') {
        return this.getThematicForCurrentSchoolLevel();
      }

      return dataset[attributeName] || '';
    }

    return this.urlInfo[cookieName] || '';
  }

  // GETTER / SETTER

  static getAttributeName(cookieName) {
    return cookieName === 'establishment' && document.querySelector('.custom-theme')
      ? 'translatedestablishment'
      : cookieName;
  }

  getThematicsOfSchoolLevel() {
    // Get mapping of thematics by school level
    return getThematicsBySchoolLevel(this.getSchoolLevel() || this.getValueForCookie('schoolLevel', true));
  }

  getItemThematics() {
    return this.getValueForCookie('thematics', true).split(',');
  }

  getSchoolLevel() {
    return this.useUserCookies
      ? this.previousCookies.lumni_user_schoolLevel
      : this.previousCookies.schoolLevel;
  }

  getEstablishment() {
    return this.useUserCookies
      ? this.previousCookies.lumni_user_establishment
      : this.previousCookies.establishment;
  }

  setCookies() {
    this.cookies = getCookies(Cookies.getCookieNames());
  }

  getCookies() {
    return this.cookies;
  }

  getPreviousCookies() {
    return this.previousCookies;
  }

  static getDataset() {
    const themeV1 = document.querySelector('.custom-theme');

    return themeV1 ? themeV1.dataset : window.LUMNI_CONTEXT.qualification;
  }

  static getCookieNames() {
    return COOKIES_STUDENT
      .concat(COOKIES_CONTAINERS)
      .concat(COOKIES_USER);
  }

  static createCustomCookies(cookiesToCreate, value, cookieDaysDuration = 365) {
    cookiesToCreate.forEach((cookie) => {
      document.cookie = `${cookie}=${value}; expires=${getCookieExpirationDate(cookieDaysDuration)}; path=/; secure`;
    });
  }

  static resetCustomCookies(cookiesToReset) {
    cookiesToReset.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    });
  }
}

export default Cookies;
