// V2
import { List } from '@v2/organisms/list/list';
import { Header } from '@v2/organisms/header/header';
import UniverseSelector from '@v2/organisms/header/universeSelector';
import { CardRedirection } from '@global-js/modules/card/card';
import { Collapse } from '@global-js/modules/utils/collapse';
import { userA11y } from '@global-js/modules/user/user_a11y';
import { formA11y } from '@global-js/modules/form/form_a11y';
import { Playlist } from '@global-js/modules/recommendation/playlist';
import { Share } from '@global/organisms/social/share_popup/social_share_popup';
import { UserMenuMyContents } from '@v2/molecules/user/menu-my-contents/user_menu-my-contents';
import { getHistoricContents } from '@global-js/modules/historic/getHistoricContents';
import { UserValidate } from '@v2/pages/user/my_information/UserValidate';
import { quitPageListener } from '@global-js/security/listeners/quit-page-listener';
import { establishmentTabs } from '@v2/organisms/establishment_tabs/establishment_tabs';
import { updateSuccessPopin } from '@v2/organisms/popup/back_to_school/popup_back_to_school';
import { MenuNavBarV2 } from '@v2/molecules/menu/menuV2_nav_bar';
import { MenuV2 } from '@v2/molecules/menu/menuV2';
import { SelectorTag } from '@v2/organisms/selector/tag/selector-tag';
import { Tabs } from '@global-js/modules/tabs/tabs';
import { setupOnClickChapterItem } from '@v2/organisms/selector/chapter/selector_chapter_v2';
import { setupOnClickIncitationProfile } from '@v2/pages/security/landing/incitation_profile';
import { FilterByType } from '@v2/molecules/filter/filter_type/filter_type';
import Quiz from '@v2/organisms/quiz/quiz';
import Iframe from '@v2/molecules/iframe/game/iframe_game';
import { myContents } from '@v2/pages/user/my_contents/user_my_contents';
import { NavigationRecorder } from '@global-js/modules/navigation-recorder/NavigationRecorder';
import { hitClickSeeMoreLinks } from './see_more/see_more';
import { Containers } from './recommendation/containers';
import { Segment } from './recommendation/segment';
import { articleTracking } from '../../../../v2/components/pages/content/article-v2/description_article';
import { isConnected } from './security/session/user';
import { GamificationRecorder } from './navigation-recorder/GamificationRecorder';
import NavBottom from '../../../../v2/components/organisms/nav_bottom/nav_bottom';
import { profilGamificationPopinConsent } from '../../../../v2/components/pages/user/my_profil/gamificationPopin';

export const Base = (cookies) => {
  // Tracking
  articleTracking();

  // Components
  Header();

  if (document.querySelector('.header__list--establishments') !== null) {
    const univerSeclector = new UniverseSelector();
    univerSeclector.init();
  }

  List();
  SelectorTag();
  MenuV2();
  new NavBottom().init();

  // Utils
  CardRedirection();
  Collapse();
  MenuNavBarV2();
  FilterByType();

  // A11y
  userA11y();
  formA11y();

  // Reco
  Segment(cookies);
  Containers(cookies);
  Playlist(cookies);

  // User
  myContents();
  UserMenuMyContents();
  getHistoricContents(cookies);
  UserValidate();
  profilGamificationPopinConsent();

  // HPP
  establishmentTabs();

  // Chapter
  if (document.querySelector('.chapter-tabs')) {
    Tabs('.chapter-tabs__tab-buttons', true);
  }

  // Chapter preview
  if (document.querySelector('.list-chapter')) {
    setupOnClickChapterItem();
  }

  // tracking landing incitation Profile
  if (document.querySelector('.incitation-profile__container')) {
    setupOnClickIncitationProfile();
  }

  // tracking see moore links
  hitClickSeeMoreLinks();

  // Share
  Share();

  // Update schoolLevel Popin
  updateSuccessPopin();

  if (document.querySelector('.container--user-content')) {
    quitPageListener();
  }

  // Init Quiz
  if (document.querySelector('#quiz') !== null) {
    const quiz = new Quiz();
    quiz.init();
  }

  // Init Iframe Game
  if (document.querySelector('.game__iframe') !== null) {
    const iframe = new Iframe();
    iframe.init();
  }

  if (isConnected()) {
    GamificationRecorder();
    NavigationRecorder();
  }
};
