import ProgressBar from '@v2/molecules/quiz/progress_bar/progress_bar';
import { isiOS, isSafari } from '@global-js/utils/browser';
import { loadIframe } from '../../molecules/iframe/iframe';
import { getCookies } from '../../../../global/assets/scripts/utils/cookies';
import { isConnected } from '../../../../global/assets/scripts/modules/security/session/user';
import { ajaxJson, ajaxHTML } from '../../../../global/assets/scripts/utils/ajax';

const CLASS_DEFAULT_ANSWER = 'quiz-answer--default';
const CLASS_ANSWER_ANIMATE_WRONG = 'quiz-answer--animate-wrong';
const CLASS_ANSWER_ANIMATE_GOOD = 'quiz-answer--animate-good';
const EXPLANATION_LABEL_ERROR = 'quiz-explanation__label__error';
const EXPLANATION_LABEL_SUCCESS = 'quiz-explanation__label__success';
const CLASS_HIDDEN = 'hidden';

const ANSWER_GOOD = 'Bonne réponse !';
const ANSWER_WRONG = 'Mauvaise réponse !';

/**
 * @param {string} action
 * @param {number} question
 * @param {number} nbQuestions
 * @param {string} resultLabel
 * @private
 */
const _dispatchView = (action, question, nbQuestions, resultLabel = '') => {
  document.dispatchEvent(new CustomEvent('Tracking:view', {
    detail: {
      type: 'quiz',
      data: {
        action, question, nbQuestions, resultLabel,
      },
    },
  }));
};

/**
 * @param {String} type
 * @param {String} slug
 */
const trackingClick = (type, slug) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: { type, data: { slug } },
  }));
};

/**
 * @param {Object} data
 */
const dispatch = (data) => {
  document.dispatchEvent(new CustomEvent('navigation:quiz:record', {
    detail: data,
  }));
};

class Quiz {
  constructor() {
    this.quiz = document.querySelector('#quiz');
    this.quizIframe = document.querySelector('.quiz iframe');
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.intro = document.querySelector('#quizStart');
    this.start = document.querySelector('#quizStartBtn');
    this.questions = document.querySelector('#quizQuestions');
    this.end = document.querySelector('#quizEnd');
    this.controls = document.querySelector('#quizBtnControls');
    this.nextQuestion = document.querySelector('#nextQuestion');
    this.nextQuiz = document.querySelector('#quizNext');
    this.review = document.querySelector('#quizReview');
    this.seeRelatedQuizzes = document.querySelector('.quiz-end__link');
    this.retries = document.querySelectorAll('.quiz-btn-retry');
    this.fullScreen = document.querySelector('.fullscreen-quiz');
    this.progressBar = new ProgressBar();
    this.quizTitle = document.getElementById('quizTitle');
    this.quizAnswer = document.querySelector('.quiz-answer[data-position="1"]');
    this.quizCheckboxTabs = document.querySelectorAll('.quiz-checkbox');
    this.quizSlug = this.quiz.dataset.slug;
    this.btnRelatedQuiz = document.querySelectorAll('.quiz-end__associated');
    this.totalQuestions = 0;
    this.currentQuestion = 1;
    this.lock = false;
    this.reviewing = false;
    this.score = 0;
  }

  init() {
    if (this.quiz === null) {
      return;
    }

    this.getRate();

    if (this.quizIframe === null) {
      this.enableEvents();
      this.progressBar.init();
      this.totalQuestions = document.querySelector('#totalQuestions')?.dataset.value;

      return;
    }

    this.start.addEventListener('click', () => this.onClickStart());
    this.fullScreen.addEventListener('click', () => this.onClickFullScreen());
    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', Quiz.exitHandler, false);
      document.addEventListener('mozfullscreenchange', Quiz.exitHandler, false);
      document.addEventListener('MSFullscreenChange', Quiz.exitHandler, false);
      document.addEventListener('webkitfullscreenchange', Quiz.exitHandler, false);
    }
  }

  enableEvents() {
    this.start?.addEventListener('click', () => this.onClickStart());
    this.nextQuestion?.addEventListener('click', () => this.onClickNext());
    this.review?.addEventListener('click', () => this.onClickReview());
    this.nextQuiz?.addEventListener('click', () => Quiz.onClickNextQuiz());
    this.seeRelatedQuizzes?.addEventListener('click', (event) => Quiz.onClickSeeRelatedQuizzes(event));
    this.fullScreen?.addEventListener('click', () => this.onClickFullScreen());

    this.questions?.addEventListener('click', ({ target }) => {
      const element = target.closest('li');
      const quizCheckbox = document.querySelector('.quiz-checkbox');
      quizCheckbox.setAttribute('aria-checked', 'true');
      if (!this.lock && element !== null && element.classList.contains('quiz-answer')) {
        this.onClickAnswer(element);
      }
    });

    [].forEach.call(this.retries, (element) => {
      element.addEventListener('click', () => this.onClickRetry());
    });

    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', Quiz.exitHandler, false);
      document.addEventListener('mozfullscreenchange', Quiz.exitHandler, false);
      document.addEventListener('MSFullscreenChange', Quiz.exitHandler, false);
      document.addEventListener('webkitfullscreenchange', Quiz.exitHandler, false);
    }
  }

  getRate() {
    if (!isConnected()) return;
    const cookies = getCookies();
    const thematicCookie = cookies.thematic;
    const imgRate = this.quiz.querySelector('.quiz-start__grade');
    const arrayRates = ['A', 'B', 'C', 'D'];

    ajaxHTML(`${window.location.origin}/ajax/member-note-letter-quiz-unit-content?slug=${this.quizSlug}&thematic=${thematicCookie}`, 'get', null, false, 'no-cache')
      .then((response) => response.text())
      .then((grade) => {
        const rate = grade.replace(/"/g, '');
        if (!arrayRates.includes(rate)) return;
        this.quiz.querySelector('.quiz-start__img img').classList.add('quiz-start__with-rate');
        imgRate.src = `${window.location.origin}/images/emojis/notes/${rate}.png`;
        imgRate.alt = `Ta dernière note sur ce quiz est : ${rate}`;
        imgRate.classList.add('active');
      })
      .catch((e) => console.log(e.message));
  }

  onClickStart() {
    this.intro.classList.add(CLASS_HIDDEN);
    trackingClick('quiz', this.quizSlug);

    if (this.quizIframe === null) {
      this.fullScreen.classList.remove(CLASS_HIDDEN);
      this.questions.classList.remove(CLASS_HIDDEN);

      dispatch({ progress: 0, score: 0 });
      _dispatchView('quizStep', 1, this.totalQuestions);

      this.quizDescribed();

      return;
    }

    this.quizIframe.parentNode.classList.remove(CLASS_HIDDEN);
    this.fullScreen.classList.remove(CLASS_HIDDEN);
    loadIframe(this.quizIframe, 'quiz');
  }

  quizDescribed() {
    const ariaLabelH1 = this.quizTitle.getAttribute('aria-label');
    this.quizAnswer.setAttribute('aria-labelledby', 'quizTitle');
    this.quizAnswer.setAttribute('aria-label', ariaLabelH1);
  }

  onClickAnswer(answer) {
    // Lock UI to prevent the user to click on others answers
    [].forEach.call(this.quizCheckboxTabs, (element) => {
      element.setAttribute('tabindex', '-1');
    });

    this.lock = true;

    const isCorrect = answer.dataset.correct === 'true';
    const question = document.querySelector(`[data-question='${this.currentQuestion}']`);

    question.setAttribute('data-correct', isCorrect.toString());

    Quiz.toggleAnswerState(answer, isCorrect);
    Quiz.toggleGoodAnswer(question, isCorrect);
    Quiz.toggleExplanation(question, isCorrect);

    this.updateScore(isCorrect);
    this.toggleControls();
  }

  onClickNext() {
    // Unlock UI to allow the user to click on others answers unless he is reviewing
    this.lock = this.reviewing;

    // Hide current question
    Quiz.toggleQuestion(this.getCurrentQuestion());

    if (this.currentQuestion + 1 > this.totalQuestions) {
      this.onEnd();
      return;
    }

    // Display next question
    this.currentQuestion += 1;
    Quiz.toggleQuestion(this.getCurrentQuestion());

    this.progressBar.advanceTo(this.currentQuestion);
    this.toggleControls();

    if (window.innerWidth <= 768) {
      document.querySelector('main')
        .scrollIntoView(true);
    }

    [].forEach.call(this.quizCheckboxTabs, (element) => {
      element.setAttribute('tabindex', '0');
    });

    _dispatchView('quizStep', this.currentQuestion, this.totalQuestions);
  }

  onClickReview() {
    this.lock = true;
    this.reviewing = true;
    this.currentQuestion = 1;

    this.end.classList.add(CLASS_HIDDEN);
    this.controls.classList.remove(CLASS_HIDDEN);
    this.questions.classList.remove(CLASS_HIDDEN);
    this.questions.setAttribute('data-reviewing', 'true');

    this.progressBar.advanceTo(this.currentQuestion);
    Quiz.toggleQuestion(this.getCurrentQuestion());

    [].forEach.call(document.querySelectorAll('.quiz-answer'), (answer) => {
      Quiz.removeAnimation(answer);
    });
  }

  onClickRetry() {
    this.score = 0;
    this.lock = false;
    this.reviewing = false;

    this.end.classList.add(CLASS_HIDDEN);
    this.controls.classList.add(CLASS_HIDDEN);
    this.questions.classList.remove(CLASS_HIDDEN);
    this.questions.setAttribute('data-reviewing', '');
    this.getCurrentQuestion().classList.add(CLASS_HIDDEN);

    this.currentQuestion = 1;
    this.progressBar.advanceTo(this.currentQuestion);
    this.getCurrentQuestion().classList.remove(CLASS_HIDDEN);

    [].forEach.call(document.querySelectorAll('.container--question'), (question) => {
      question.setAttribute('data-correct', '');
    });

    [].forEach.call(document.querySelectorAll('.quiz-answer'), (answer) => {
      Quiz.removeAnimation(answer);
      if (!answer.classList.contains(CLASS_DEFAULT_ANSWER)) {
        Quiz.toggleAnswerState(answer);
      }
    });

    document.querySelectorAll('.quiz-explanation__label').forEach((explanation) => {
      explanation.classList.remove(EXPLANATION_LABEL_ERROR, EXPLANATION_LABEL_SUCCESS);
    });
  }

  onClickFullScreen() {
    if ((window.innerWidth <= 1199 && !isiOS()) && !isSafari()) {
      screen.orientation.lock('landscape')
        .catch((error) => { console.log(error); });
    }

    this.body.classList.toggle('fullscreen');

    if (window.innerWidth <= 1199 && isiOS()) {
      this.html.classList.toggle('html-fullscreen-ios');
    }

    if (this.quiz.requestFullscreen) {
      this.quiz.requestFullscreen();
    } else if (this.quiz.mozRequestFullScreen) {
      this.quiz.mozRequestFullScreen();
    } else if (this.quiz.webkitRequestFullscreen) {
      this.quiz.webkitRequestFullscreen();
    } else if (this.quiz.msRequestFullscreen) {
      this.quiz.msRequestFullscreen();
    }

    if (this.quiz.classList.contains('is-fullscreen')) {
      isSafari() ? document.webkitExitFullscreen() : document.exitFullscreen();
    }
  }

  static onClickNextQuiz() {
    const nextQuiz = document.querySelector('.playlist--container .card__imagearea > a');
    if (nextQuiz !== null) {
      window.location = nextQuiz.href;
    }
  }

  static onClickSeeRelatedQuizzes(event) {
    event.preventDefault();

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.querySelector('#playlistQuiz').offsetTop + 10,
    });
  }

  getCookiesToRedirect() {
    const cookies = getCookies();
    const establishmentCookie = cookies.establishment;
    const schoolLevelCookie = cookies.schoolLevel;
    const thematicCookie = cookies.thematic;
    const btns = this.btnRelatedQuiz;
    let link = `${window.location.origin}/tous-les-quiz`;

    if (schoolLevelCookie) {
      link = `${window.location.origin}/${establishmentCookie}/${schoolLevelCookie}/tous-les-quiz`;
    }
    if (thematicCookie) {
      link += `?matiere=${thematicCookie}`;
    }

    if (btns && btns.length > 0) {
      btns.forEach((btn) => {
        btn.addEventListener('click', () => {
          window.location.href = link;
        });
      });
    }
  }

  onEnd() {
    this.getCookiesToRedirect();
    this.questions.classList.toggle(CLASS_HIDDEN);
    this.end.classList.toggle(CLASS_HIDDEN);
    this.toggleControls();
    const cookies = getCookies();

    const result = (this.score / this.totalQuestions) * 20;

    const grades = [
      { min: 16, max: 20, grade: 'A' },
      { min: 12, max: 15.99, grade: 'B' },
      { min: 10, max: 11.99, grade: 'C' },
      { min: 0, max: 9.99, grade: 'D' },
    ];

    const grade = grades.find((range) => result >= range.min && result <= range.max)?.grade || null;

    const resultType = grade === 'A' ? 'success' : 'loose';

    this.end.querySelector('.container--quiz-end').setAttribute('data-result', resultType);
    this.end.querySelector('.quiz-end__score').textContent = this.score;
    this.end.querySelector('.quiz-end__score-message').textContent = this.score > 1 ? ' bonnes réponses / ' : ' bonne réponse / ';
    this.end.querySelector('.quiz-end__grade').src = `${window.location.origin}/images/emojis/notes/${grade}.png`;
    this.end.querySelector('.quiz-end__grade').alt = `Ta note est ${grade}`;
    this.end.querySelector('.quiz-end__decoration').src = `${window.location.origin}/images/emojis/notes/${(grade === 'A' || grade === 'B') ? 'success' : 'loose'}.png`;

    const resultLabel = Quiz.getATResultLabel(resultType);

    const quizEndRegistrationBtn = document.querySelectorAll('.quiz-end__registration');
    quizEndRegistrationBtn.forEach((btn) => btn.addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('Tracking:click', {
        detail: {
          type: 'incitation_compte',
          data: {
            action: 'registration_end_quiz',
            incitationType: 'progress_end_of_quiz',
          },
        },
      }));
    }));

    dispatch({ progress: 100, score: this.score });
    _dispatchView('quizFinished', this.totalQuestions, this.totalQuestions, resultLabel);
    if (isConnected()) {
      if (cookies.lumni_user_schoolLevel === 'autre') {
        this.end.querySelector('.quiz-end__profil').remove();
      }
      this.end.querySelector('.quiz-end__btns-connected').classList.add('active');
      this.end.querySelector('.quiz-end__btns-not-connected').remove();
      this.sendRate(this.quiz.dataset.slug, this.score);
    }

    window.usabilla_live('trigger', 'fin quiz');
  }

  sendRate(slug, note) {
    const cookies = getCookies();
    const { schoolLevel, thematic } = cookies;
    const type = 'quiz';
    const data = {
      note,
      slug,
      type,
      schoolLevel,
      thematic,
    };
    ajaxJson(`${ window.location.origin }/ajax/member-note-quiz-update`, false, data, 'post')
      .catch((e) => console.error(e.message));
  }

  static getATResultLabel(resultType) {
    if (resultType === 'success') return 'bon_score';
    return 'mauvais_score';
  }

  static toggleQuestion(question) {
    question.classList.toggle(CLASS_HIDDEN);
  }

  static toggleAnswerState(answer, isCorrect) {
    answer.classList.toggle(CLASS_DEFAULT_ANSWER);
    answer.querySelector('img').classList.toggle(CLASS_HIDDEN);

    if (isCorrect !== undefined) {
      answer.classList.add(isCorrect ? CLASS_ANSWER_ANIMATE_GOOD : CLASS_ANSWER_ANIMATE_WRONG);
    }
  }

  static toggleGoodAnswer(question, isCorrect) {
    if (!isCorrect) {
      const answer = question.querySelector('li[data-correct="true"]');
      Quiz.toggleAnswerState(answer);
    }
  }

  static toggleExplanation(question, isCorrect) {
    const explanationLabel = question.querySelector('.quiz-explanation__label');
    const explanationLabelClass = isCorrect ? EXPLANATION_LABEL_SUCCESS : EXPLANATION_LABEL_ERROR;
    explanationLabel.querySelector('span').textContent = isCorrect ? ANSWER_GOOD : ANSWER_WRONG;
    explanationLabel.classList.toggle(`${explanationLabelClass}`);
  }

  static removeAnimation(answer) {
    answer.classList.remove(CLASS_ANSWER_ANIMATE_WRONG);
    answer.classList.remove(CLASS_ANSWER_ANIMATE_GOOD);
  }

  toggleControls() {
    if (!this.reviewing) {
      this.controls.classList.toggle(CLASS_HIDDEN);
    }
  }

  getCurrentQuestion() {
    return document.querySelector(`[data-question='${this.currentQuestion}']`);
  }

  updateScore(isCorrect) {
    this.score += isCorrect ? 1 : 0;

    dispatch({
      progress: ((this.currentQuestion / this.totalQuestions) * 90),
      score: this.score,
    });
  }

  static exitHandler() {
    const fullScreenImg = document.getElementsByClassName('fullscreen-quiz--btn');
    const quiz = document.querySelector('#quiz');
    const body = document.querySelector('body');

    if ((document.fullscreenElement) || (document.webkitIsFullScreen)) {
      quiz.classList.add('is-fullscreen');
      fullScreenImg[0].setAttribute('src', `${window.location.origin}/images/icons/shape-fullScreen.svg`);
    } else {
      quiz.classList.remove('is-fullscreen');
      body.classList.remove('fullscreen');
      fullScreenImg[0].setAttribute('src', `${window.location.origin}/images/icons/fullscreen-quiz.svg`);
    }
  }
}

export default Quiz;
