import { Theme } from './modules/theme/theme';
import Cookies from './Cookies';
import SecurityApp from './security-index';
import SuccessRegistration from './successRegistration';
import { cmpOptoutPopin, listenConsentChange, changeCloseLabelTxt } from './Consent';
import { Security } from './modules/security/Security';
import { isConnected } from './modules/security/session/user';
import Newsletter from '../../components/organisms/newsletter/newsletter';
import { AppCommon } from './AppCommon';

let previousCookies = {};
let cookies = null;

const canCustomizeTheme = (isUserConnected, hasToCustomizeTheme) => window.isPageAnnex
  || (window.isPageUser && !isUserConnected)
  || (document.querySelector('.menu--hidden') !== null && hasToCustomizeTheme)
  || (window.LUMNI_CONTEXT.route === 'search')
  || window.isPageGamificationLanding;

// Updating theme based on cookies
const customizeTheme = (isUserConnected) => {
  const hasToCustomizeTheme = typeof previousCookies.establishment !== 'undefined';
  const canCustomize = canCustomizeTheme(isUserConnected, hasToCustomizeTheme);

  if (canCustomize) {
    Theme(previousCookies);
  }
};

export const initCommonApp = () => {
  try {
    // When the App has been loaded we get information of the current user.
    Security();
    SecurityApp.init();
    SuccessRegistration.init();
    new Newsletter().init();

    if (!document.querySelector('#securityPage')) {
      // Init Cookies
      cookies = new Cookies(isConnected());
      cookies.init();

      // Get cookies from the previous page to know what theme we should apply to the current page
      previousCookies = cookies.getPreviousCookies();
      customizeTheme(isConnected());

      cmpOptoutPopin();
      listenConsentChange();
      changeCloseLabelTxt();

      const cookiesData = cookies === null ? {} : cookies.getCookies();
      AppCommon(previousCookies, cookiesData);

      return {
        previousCookies,
        cookies: cookiesData,
      };
    }
  } catch (error) {
    console.log('Error', error);
  }

  return {
    previousCookies: {},
    cookies: {},
  };
};
