import { getQueryParams } from '@global-js/utils/queryParameters';
import { Pagination } from '@v2/molecules/pagination/pagination';

/**
 * @param {Event} event
 * @private
 */
const _onClickPagination = (event) => {
  event.preventDefault();
  window.scrollTo({ top: 0, behavior: 'smooth' });

  const url = new URL(event.currentTarget.href);
  const data = getQueryParams(url);

  document.dispatchEvent(new CustomEvent('Search:pager:click', {
    detail: {
      page: parseInt(data.page || '1', 10),
    },
  }));
};

/**
 * @private
 */
const _enableSearchPagination = () => {
  if (document.querySelector('.pagerfanta-v3')) {
    Pagination();
  }

  const pagination = document.querySelector('.pagerfanta-v2') || document.querySelector('.pagerfanta-v3');

  if (!pagination) {
    return;
  }

  pagination.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', _onClickPagination, false);
  });
};

/**
 * @constructor
 */
export const SearchPager = () => {
  document.addEventListener('Search:refresh', _enableSearchPagination, false);
};
