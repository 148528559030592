import { createCookie } from '@global-js/utils/cookies';
import { getCookie } from '../../../../../global/assets/scripts/modules/tracking/Usabilla/cookiesTracking';
import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';

const body = document.querySelector('body');
const popinAdGamification = document.querySelector('.ad_gamification');
const coins = document.querySelectorAll('.btn_lumniz__coin');
const closeCross = document.querySelector('.ad_gamification--close-cross');
const btnsLogin = document.querySelector('.ad_gamification--btns');

const _dispatchTrackingImpressionEvents = () => {
  document.dispatchEvent(new CustomEvent('Tracking:impression', {
    detail: {
      type: 'popin_ad_impression',
    },
  }));
};

/**
 * @param {string} action
 * @private
 */
const _dispatchTrackingClicks = (action) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'popin_ad_gamification',
      data: { action },
    },
  }));
};

const _onClickInBtns = ({ currentTarget }) => {
  _dispatchTrackingClicks(currentTarget.dataset.action);
};

const showPopin = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
    popinAdGamification.classList.add('active');
    coins.forEach((coin) => {
      coin.classList.add('lumniz_bounce--infinite');
    });

    _dispatchTrackingImpressionEvents();
    createCookie('lumni_popin_advertising_gamification', 'true', 365);
  }, 1000);
};

const _closePopin = () => {
  popinAdGamification.classList.remove('active');
  body.classList.remove('body-blocked');
  coins.forEach((coin) => {
    coin.classList.remove('lumniz_bounce--infinite');
  });
};

const closePopinOnClick = () => {
  closeCross.addEventListener('click', _closePopin);
};

const closePopinOnChangePage = () => {
  window.addEventListener('unload', _closePopin);
};

const closePopinOnClickNav = () => {
  const header = document.querySelector('.container--header-v2');
  header.addEventListener('click', (event) => {
    const targetElement = event.target;
    if (popinAdGamification.contains(targetElement)) return;

    _closePopin();
  });
};

const removeBtnsLogin = () => {
  if (!isConnected()) return;
  btnsLogin.classList.add('hidden');
};

export const advertisingPopinGamification = () => {
  if ((isConnected()) || (getCookie('lumni_popin_advertising_gamification'))) return;

  showPopin();
  removeBtnsLogin();
  closePopinOnClick();
  closePopinOnChangePage();
  closePopinOnClickNav();

  const links = btnsLogin.querySelectorAll('a');
  links.forEach((link) => {
    link.addEventListener('click', _onClickInBtns, false);
  });
};
