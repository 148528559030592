import { getCookie } from '../../../../global/assets/scripts/modules/tracking/Usabilla/cookiesTracking';
import { isConnected } from '../../../../global/assets/scripts/modules/security/session/user';

/**
 * @param {string} action
 * @private
 */
const _dispatchEvents = (action) => {
  document.dispatchEvent(new CustomEvent('Tracking:click', {
    detail: {
      type: 'bubble_incentive',
      data: { action },
    },
  }));
};

/**
 * @param {HTMLElement} currentTarget
 * @private
 */
const _onClickInBubble = ({ currentTarget }) => {
  _dispatchEvents(currentTarget.dataset.action);
};

export const displayBubble = () => {
  if ((isConnected()) || (getCookie('incentiveBubble')) || (!getCookie('lumni_popin_advertising_gamification'))) return;

  const bubble = document.querySelector('.incentive_bubble');

  if ((!bubble)) return;

  bubble.classList.add('incentive_bubble--active');

  const links = bubble.querySelectorAll('a');

  links.forEach((link) => {
    link.addEventListener('click', _onClickInBubble, false);
  });

  const timeToExpire = 900000; /* 15min =  15 * 60 * 1000 */

  setTimeout(() => {
    bubble.classList.remove('incentive_bubble--active');
    const now = new Date();
    const expire = now.setTime(now.getTime() + timeToExpire);
    const expireDate = new Date(expire).toUTCString();
    document.cookie = `incentiveBubble=true;expires=${expireDate};path=/`;
  }, 10000);
};
