import { openPopup, closePopup } from '@global/organisms/popup/popup';
import { toggleFilter } from '@global-js/modules/filter/filter';
import { filterCards } from '@global-js/modules/filter/filterCards';

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClickFilter = ({ target }) => {
  const filter = target.closest('.filters-v2__filter');
  const parent = filter.parentNode;

  // If the filter is a link we stop here and follow the link
  if (parent.hasAttribute('href')) {
    return;
  }

  const item = filter.closest('.thematic-filter-item')?.getAttribute('data-filter');
  if (item) {
    localStorage.setItem('lastFilterSelect', item);
  }

  // Else we apply filter on the current page
  const filters = toggleFilter(filter, '.filters-v2__filter');
  const { key, pagination } = parent.dataset;

  if (pagination) {
    document.dispatchEvent((new CustomEvent('filters:type', { detail: filters })));
    return;
  }

  // Else we filter the current cards of the list
  filterCards(filters, `#${key}`, ['tags', key]);
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClickToggleFilterMobile = ({ target }) => {
  const parent = target.closest('.filters-v2');
  parent.setAttribute('data-open', (!(parent.dataset.open === 'true')).toString());
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClickBtnApplyFilter = ({ target }) => {
  const popin = target.closest('.popup--filter');
  const filterBtn = document.querySelector('.btn-popin-filter');
  filterBtn.classList.remove('hidden');
  closePopup(popin);
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClickBtnResetFilter = ({ target }) => {
  const popup = target.parentNode.closest('.popup');
  const filters = popup.querySelector('.filters-v2');
  filters.querySelector('[data-filter="all"]')?.click();
  localStorage.removeItem('lastFilterSelect');
};

/**
 * @param {HTMLElement} target
 * @private
 */
const _onClickBtnFilter = ({ target }) => {
  const btn = target.closest('.btn-popin-filter');
  const popin = btn.parentNode.querySelector('.popup--filter');

  if (popin.dataset.active === 'true') return;
  btn.classList.add('hidden');
  openPopup(popin);
};

const _onClosePopupFilter = () => {
  document.addEventListener('popup-closed', () => {
    const filterBtn = document.querySelector('.btn-popin-filter');
    if (!filterBtn) return;
    filterBtn.classList.remove('hidden');
  });
};

export const FilterByType = () => {
  const filters = document.querySelectorAll('.filters-v2 .filters-v2__filter');

  if (filters.length === 0) return;

  const toggleFilterElement = document.querySelectorAll('.filter__switch');
  const filterBtn = document.querySelectorAll('.btn-popin-filter');
  const applyFiltersBtn = document.querySelectorAll('.btn--apply-filters');
  const resetFiltersBtn = document.querySelectorAll('.btn--reset-filters');

  filters.forEach((filter) => filter.addEventListener('click', _onClickFilter, false));
  toggleFilterElement?.forEach((filter) => filter.addEventListener('click', _onClickToggleFilterMobile, false));
  filterBtn?.forEach((btn) => btn.addEventListener('click', _onClickBtnFilter, false));
  applyFiltersBtn?.forEach((btn) => btn.addEventListener('click', _onClickBtnApplyFilter, false));
  resetFiltersBtn?.forEach((btn) => btn.addEventListener('click', _onClickBtnResetFilter, false));

  _onClosePopupFilter();
};
