import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';

class CardListEventDispatcher {
  constructor() {
    this.cards = document.querySelectorAll('.card_list--playlist');
  }

  init() {
    if (!isConnected()) return;
    this.handleClickEvent();
  }

  handleClickEvent() {
    this.cards.forEach((card) => {
      card.addEventListener('click', this.dispatchTrackingCardPlaylist);
    });
  }

  dispatchTrackingCardPlaylist = () => {
    document.dispatchEvent(new CustomEvent('Tracking:click', {
      detail: {
        type: 'card_list_playlist_click',
      },
    }));
  };
}

export default CardListEventDispatcher;
