import { updateState } from '../utils';
import { ajaxJson } from '../../../../../../global/assets/scripts/utils/ajax';

/**
 * @private
 */
const _dispatchEvents = () => {
  document.dispatchEvent(new CustomEvent('registration:email:exists'));
};

/**
 * @param {string} email
 * @private
 */
const _checkIfEmailExists = (email) => {
  const URL = window.location.origin;

  ajaxJson(`${URL}/ajax/user/is-email-exists?email=${email}`, false)
    .then((result) => {
      if (result) {
        _dispatchEvents();
      }
    })
    .catch((e) => console.error(e.message));
};

/**
 * @param {HTMLInputElement} input
 */
export const checkEmail = (input) => {
  const mailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/g);
  const { value } = input;
  const isValid = mailRegex.exec(value) !== null;
  const warning = document.querySelector('#warningEmailExists');

  if (isValid && warning !== null) {
    warning.classList.add('is-hidden');
    _checkIfEmailExists(value);
  }

  updateState(input, isValid);
};
