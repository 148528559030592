import { ajaxHTML } from '@global-js/utils/ajax';
import Lazy from '@global-js/Lazy';
import { SliderV2 } from '@v2/organisms/slider/v2/slider';
import { isBackNavigation } from '@global-js/modules/utils/isBackNavigation';
import { Filters } from '@v2/molecules/filter/filter_search/filters';
import { RestrictedCards } from '@v2/molecules/card/restricted_cards';
import { isUserAbroad } from '@global-js/modules/security/session/user';
import { createCookie } from '@global-js/utils/cookies';

const CONFIG = {
  form: '#searchByCompletion',
  queryInput: '#formQuery',
  queryInputCancel: '.search-completion__input__cancel',
  schoolLevelsInputs: '#search_schoolLevel input',
  thematicsInputs: '#search_thematic input',
  collectionsInputs: '#search_content-types input',
  orderInputs: '#search_order input',
  filtersNavToggles: '.search__filter',
};

const SEARCH_QUERY_SESSION = 'lumni_search_query';

const SEARCH_URL = '/ajax/search-beta-v2/results';

let abortCallAsync = false;
let timeout = null;
let previousValue = null;
let isAlreadyBeenLoaded = false;
let DEBOUNCE;
let nbQuery = 0;

/**
 * @param {HTMLElement} container
 * @private
 */
const _dispatchEvents = (container) => {
  document.dispatchEvent((new CustomEvent('refreshAddPlaylist', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('refreshCardRedirection', { detail: { container } })));
  document.dispatchEvent((new CustomEvent('noResponsiveGameCardsInList', { detail: { container } })));
  document.dispatchEvent(new CustomEvent('Search:refresh'));

  if (!isAlreadyBeenLoaded) {
    document.dispatchEvent(new CustomEvent('Tracking:view'));
  }

  isAlreadyBeenLoaded = true;
  Lazy.init();
};

/**
 * @return {{thematics: string, order_by: string, levels: string}}
 * @private
 */
const _getFilters = () => {
  const levels = [];
  const establishments = [];
  const thematics = [];
  const collections = [];
  const levelsChecked = document.querySelectorAll(`${ CONFIG.schoolLevelsInputs }:checked`);
  const thematicsChecked = document.querySelectorAll(`${ CONFIG.thematicsInputs }:checked`);
  const collectionChecked = document.querySelectorAll(`${ CONFIG.collectionsInputs }:checked`);
  levelsChecked.forEach((input) => {
    levels.push(input.value);
    establishments.push(input.dataset.establishment);
  });
  thematicsChecked.forEach((input) => thematics.push(input.value));
  collectionChecked.forEach((input) => {
    collections.push(input.name);
  });

  if (typeof levels[0] !== 'undefined') {
    createCookie('schoolLevel', levels[0], 365);
    if (typeof establishments[0] !== 'undefined') {
      createCookie('establishment', establishments[0], 365);
    }
  }
  return {
    levels: levels.join(','),
    thematics: thematics.join(','),
    collections: collections.join(','),
    order_by: 'relevance',
  };
};

/**
 * @private
 */
const _displayGeoBlockedFilter = () => {
  const isAbroadFilter = document.querySelector('.search__geoblocked');

  if (isUserAbroad()) {
    isAbroadFilter.dataset.isabroad = 'true';
  } else {
    isAbroadFilter.dataset.isabroad = 'false';
  }
};

/**
 * @private
 */
const _getNumberResults = () => {
  const btn = document.querySelector('.search__btn__results');
  const nbResults = document.querySelector('.search__total--referer').getAttribute('data-total');
  if (nbResults === '0') {
    btn.innerHTML = '0 résultat';
  } else {
    btn.innerHTML = nbResults === '1' ? '1 résultat' : `${ nbResults } résultats`;
  }
};

/**
 * @param {boolean} onFiltersChange
 * @param {number} page
 * @param {string|null} suggestion
 * @private
 */
const _search = (onFiltersChange = false, page = 1, suggestion = null) => {
  clearTimeout(timeout);

  const query = suggestion || document.querySelector(CONFIG.queryInput).value.trim();
  const queryString = query.length === 0 ? '' : `query=${ query }&`;

  if (suggestion !== null) {
    document.querySelector(CONFIG.queryInput).value = query;
  }

  const geoBlockedBtn = document.querySelector('#not_geoblocked');
  const isAbroad = isUserAbroad() && geoBlockedBtn.checked === true ? '&abroad=true' : '';

  // Stop here if the last query is still the same
  if (query.length > 0 && query === previousValue && !onFiltersChange) return;

  const filters = _getFilters();
  const type = query.length === 0 ? 'publishedAt' : 'relevance';
  const queryStringFull = `${ queryString }levels=${ filters.levels }&thematics=${ filters.thematics }&page=${ page }&order_by=${ type }${ isAbroad }&collections=${ filters.collections }`;

  if (nbQuery >= 1) {
    DEBOUNCE = 500;
  } else {
    DEBOUNCE = 0;
  }

  if (filters.collections.includes('serie')) {
    filters.collections = filters.collections.replace('serie', 'serie,brand,program');
  }

  timeout = setTimeout(() => {
    ajaxHTML(`${ window.location.origin }${ SEARCH_URL }?${ queryStringFull }`, 'get', null, abortCallAsync)
      .then((response) => response.text())
      .then((template) => {
        const container = document.querySelector('.grid--search__list');
        container.innerHTML = template;
        // Keep track of the last query
        previousValue = query;
        // Re bind events on cards
        _dispatchEvents(container);
        SliderV2(container);
        _displayGeoBlockedFilter();
        RestrictedCards();
        _getNumberResults();
        nbQuery++;

        // Save search query
        sessionStorage.setItem(SEARCH_QUERY_SESSION, query);
        abortCallAsync = true;
      })
      .catch((e) => console.log(e.message));
  }, DEBOUNCE);
};

/**
 * @private
 */
const _onKeyUp = () => {
  _search();
};

/**
 * @private
 */
const _onSubmit = (event) => {
  event.preventDefault();
};

/**
 * @private
 */
const _ariaExpanded = (target) => {
  target.addEventListener('click', () => {
    setTimeout(() => {
      const isActive = target.getAttribute('data-active') === 'true';
      target.setAttribute('aria-expanded', isActive.toString());
    }, 10);
  });
};

/**
 * @private
 */
const _ariaChecked = (target) => {
  target.addEventListener('change', (event) => {
    target.ariaChecked = !!event.currentTarget.checked;
  });
};

/**
 * @private
 */
function _handleEnterKey(e) {
  const enterKey = ['U+000A', 'Enter', 13];
  if (enterKey.includes(e.keyIdentifier) || enterKey.includes(e.keyCode)) {
    if (e.target.id === 'formQuery') {
      e.preventDefault();
    }
  }
}

export const SearchBeta = () => {
  const form = document.querySelector(CONFIG.form);
  form.addEventListener('submit', _onSubmit, false);

  const input = document.querySelector(CONFIG.queryInput);
  input.addEventListener('keyup', _onKeyUp, false);
  input.addEventListener('keydown', _handleEnterKey, true);

  const inputCancel = document.querySelector('.search--heading__input__cancel');
  inputCancel.addEventListener('click', (event) => {
    event.preventDefault();
    input.value = '';
    input.focus();
    _search();
  }, false);

  const checkBoxes = [
    ...document.querySelectorAll(CONFIG.schoolLevelsInputs),
    ...document.querySelectorAll(CONFIG.thematicsInputs),
    ...document.querySelectorAll(CONFIG.collectionsInputs),
  ];

  checkBoxes.forEach((element) => _ariaChecked(element));

  const filtersNavToggles = document.querySelectorAll(CONFIG.filtersNavToggles);
  filtersNavToggles.forEach((element) => _ariaExpanded(element));

  document.addEventListener('Search:filters:change', () => _search(true), false);
  document.addEventListener('Search:pager:click', (event) => _search(true, event.detail.page), false);
  document.addEventListener('Search:suggestion:click', (event) => _search(true, 1, event.detail.query), false);

  // Check if the user is navigating backward
  const isBack = isBackNavigation();

  // Get query from last search
  const lastQuery = sessionStorage.getItem(SEARCH_QUERY_SESSION);
  // Get current query
  const currentQuery = document.querySelector(CONFIG.queryInput).value;

  // Set query from the last search if the user is navigating backward
  if (isBack && lastQuery) {
    document.querySelector(CONFIG.queryInput).value = lastQuery;
  }

  // Init Filters
  Filters();

  // If user is navigating forward and there is a query we call the search
  if (!isBack && currentQuery) {
    _search();
  }

  // Reset session storage if user is navigating forward
  if (!isBack) {
    sessionStorage.removeItem(SEARCH_QUERY_SESSION);
  }

  // geoBlocked filter
  const geoBlockedBtn = document.querySelector('#not_geoblocked');
  _displayGeoBlockedFilter();
  RestrictedCards();
  geoBlockedBtn.addEventListener('change', _search, false);

  _search();
};
