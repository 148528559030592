import { ajaxJson, ajaxHTML } from '@global-js/utils/ajax';
import { isConnected } from '../../../../../global/assets/scripts/modules/security/session/user';
import { trapFocus } from '../../../../../global/assets/scripts/utils/trapFocus';

const URL_GET_MEMBER_REACTIONS = `${window.location.origin}/ajax/member_reactions`;
const URL_MEMBER_REACTIONS_DELETE = `${window.location.origin}/ajax/member_reactions_delete`;
const URL_MEMBER_REACTIONS_CREATE = `${window.location.origin}/ajax/member_reactions_create`;

class BtnsLikeDislike {
  constructor(isUserConnected) {
    this.isUserConnected = isUserConnected;
    this.btnsContainer = '.btns_like_dislike';
    this.btnLike = '.btn_like';
    this.btnDislike = '.btn_dislike';
    this.iconLike = '.icon_like';
    this.iconLikeActive = '.icon_like_active';
    this.iconDislike = '.icon_dislike';
    this.iconDislikeActive = '.icon_dislike_active';
    this.iconMultipleLike = '.icon_multiple_like';
    this.closePopinBtn = '.popin__like__close';
    this.incitationContainer = '.container_incitation_like';
    this.playlistContainer = '.popin__playlist--container';
    this.btnClosePopin = '.popin__incitation__close';
  }

  init() {
    this.hasMemberReaction();
    this.addClickListener(this.btnLike, this.toggleLike.bind(this));
    this.addClickListener(this.btnDislike, this.toggleDislike.bind(this));
  }

  dispatchEventTracking = (action, type) => {
    document.dispatchEvent(new CustomEvent('Tracking:click', {
      detail: {
        type: 'incentive_account',
        data: { action, type },
      },
    }));
  };

  hasMemberReaction() {
    if (!isConnected()) return;
    const container = document.querySelector(this.btnsContainer);
    const type = container.getAttribute('data-type');
    const slug = container.getAttribute('data-slug');
    ajaxHTML(`${URL_GET_MEMBER_REACTIONS}?slug=${slug}&type=${type}`, 'get', null, false, 'no-cache')
      .then((response) => response.text())
      .then((text) => {
        if (text === '[]') return;
        const reactions = JSON.parse(text);
        const data = reactions[0]?.category;
        this.displayActiveBtn(container, data);
      })
      .catch((error) => console.error(error));
  }

  displayActiveBtn(container, data) {
    const buttons = {
      like: this.btnLike,
      dislike: this.btnDislike,
    };
    const btn = container.querySelector(buttons[data]);
    btn.classList.add('active');
  }

  addClickListener(btnClass, handler) {
    const container = document.querySelector(this.btnsContainer);
    const btn = container.querySelector(btnClass);
    btn.addEventListener('click', () => handler(btn));
  }

  toggleLike(btn) {
    if (!isConnected()) {
      this.displayPopinIncitation(URL_MEMBER_REACTIONS_CREATE);
      return;
    }
    const container = document.querySelector('.save-item');
    const type = container.getAttribute('data-type');
    const slug = container.getAttribute('data-slug');
    if (btn.classList.contains('active')) {
      const data = {
        slug,
        type,
        category: 'like',
      };
      ajaxJson(URL_MEMBER_REACTIONS_DELETE, false, data, 'post')
        .catch((e) => console.error(e.message));
      this.cancelAction(btn);
      return;
    }

    const iconLikeActive = btn.querySelector(this.iconLikeActive);
    const iconMultipleLike = btn.querySelector(this.iconMultipleLike);
    const data = {
      slug,
      type,
      category: 'like',
    };
    ajaxJson(URL_MEMBER_REACTIONS_CREATE, false, data, 'post')
      .catch((e) => console.error(e.message));
    this.handleAction(btn, iconLikeActive, iconMultipleLike);
    const btnDislike = document.querySelector(this.btnDislike);
    if (btnDislike?.classList.contains('active')) {
      btnDislike.classList.remove('active');
    }
  }

  toggleDislike(btn) {
    if (!isConnected()) {
      this.displayPopinIncitation(URL_MEMBER_REACTIONS_CREATE);
      return;
    }
    const container = document.querySelector('.save-item');
    const type = container.getAttribute('data-type');
    const slug = container.getAttribute('data-slug');
    if (btn.classList.contains('active')) {
      const data = {
        slug,
        type,
        category: 'dislike',
      };
      ajaxJson(URL_MEMBER_REACTIONS_DELETE, false, data, 'post')
        .catch((e) => console.error(e.message));
      this.cancelAction(btn);
      return;
    }
    const data = {
      slug,
      type,
      category: 'dislike',
    };
    ajaxJson(URL_MEMBER_REACTIONS_CREATE, false, data, 'post')
      .catch((e) => console.error(e.message));
    this.handleAction(btn);
    const btnLike = document.querySelector(this.btnLike);
    if (btnLike?.classList.contains('active')) {
      btnLike.classList.remove('active');
    }
  }

  displayPopinIncitation(url) {
    const container = document.querySelector(this.incitationContainer);
    const containerPlaylist = document.querySelector(this.playlistContainer);
    ajaxHTML(`${url}`, 'post', '', false)
      .then((response) => response.text())
      .then((template) => {
        containerPlaylist.innerHTML = '';
        container.innerHTML = template;
        this.initPopin(container);
      });
  }

  initPopin(popinContainer) {
    const btnClosePopin = popinContainer.querySelector(this.btnClosePopin);
    btnClosePopin.focus();
    trapFocus(popinContainer);
    btnClosePopin.addEventListener('click', () => this.closePopin(popinContainer));
    document.addEventListener('click', (event) => this.handleClickOutside(event, popinContainer));
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.closePopin(popinContainer);
      }
    });
    const links = popinContainer.querySelectorAll('a');
    links.forEach((link) => {
      link.addEventListener('click', this.onClickInBtns, false);
    });
  }

  onClickInBtns = ({ currentTarget }) => {
    this.dispatchEventTracking(currentTarget.dataset.action, 'like_dislike');
  };

  closePopin(container) {
    container.innerHTML = '';
  }

  handleClickOutside(event, popin) {
    if (popin && !popin.contains(event.target)) {
      this.closePopin(popin);
    }
  }

  cancelAction(btn) {
    if (btn.classList.contains('active')) {
      btn.classList.remove('active');
    }
  }

  handleAction(btn, icon, iconMultiple) {
    if (!btn.classList.contains('active')) {
      btn.classList.add('active');
      if (icon) {
        icon.classList.add('updown');
        iconMultiple.classList.add('disapear');
        setTimeout(() => {
          icon.classList.remove('updown');
          iconMultiple.classList.remove('disapear');
        }, 1000);
      }
    }
  }
}

export default BtnsLikeDislike;
