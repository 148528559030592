import { ajax, displaySegment } from './utils';

const BY_THEMATICS = ['video', 'quiz'];

const state = {
  cookies: {},
  recommendationId: '#recommendation--v2',
  recommendation: null,
};

/**
 * @param {string} type
 */
const callback = (type) => {
  if (!BY_THEMATICS.includes(type)) {
    displaySegment(state.recommendation);
  }
};

/**
 * @param {string} schoolLevel
 * @param {string} thematic
 * @param {string} type
 * @param {string} slug
 */
const getRecommendationByThematics = (schoolLevel, thematic, type, slug) => {
  if (typeof schoolLevel === 'undefined' || typeof thematic === 'undefined') {
    return;
  }

  ajax(
    `recommendation/segment/${schoolLevel}/${thematic}/${type}/${slug}`,
    type,
    state.recommendationId,
  );
};

/**
 * @param {string} schoolLevel
 * @param {string} type
 * @param {string} slug
 */
const getRecommendation = (schoolLevel, type, slug) => {
  if (typeof schoolLevel === 'undefined') {
    return;
  }

  ajax(
    `recommendation-v2/${schoolLevel}/${type}/${slug}`,
    type,
    state.recommendationId,
    callback,
  );
};

/**
 * @param {Object} cookies
 * @constructor
 */
export const Segment = (cookies) => {
  state.recommendation = document.querySelector(state.recommendationId);

  if (!state.recommendation) {
    return;
  }

  state.cookies = cookies;

  const { schoolLevel, thematic } = state.cookies || '';
  const { type, slug } = state.recommendation.dataset;

  if (BY_THEMATICS.includes(type)) {
    getRecommendationByThematics(schoolLevel, thematic, type, slug);
  } else {
    getRecommendation(schoolLevel, type, slug);
  }
};
