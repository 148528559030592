export const closeNotification = () => {
  const notification = document.getElementById('js-bottom-notification');
  const closeButton = document.getElementById('js-bottom-notification-button');
  const keepConnect = document.getElementById('js-keep-connect');
  const metanav = document.querySelector('.metanav-footer-v2');

  if (notification === null) {
    return;
  }

  closeButton.addEventListener('click', () => {
    const successRegisterDiv = closeButton.parentNode.parentNode.parentNode.id;
    metanav.style.paddingBottom = '4rem';
    notification.classList.add('is-hidden');
    if (successRegisterDiv === 'js-success_registration') {
      setTimeout(() => {
        keepConnect.classList.remove('is-hidden');
      }, 3000);
    }
  });
};
