import { getQueryParams } from '@global-js/utils/queryParameters';
import { Pagination } from '../../molecules/pagination/pagination';

const CATEGORY = {
  reprendre: '#resume',
  revoir: '#replay',
};

/**
 * @param {Event} event
 * @param {function} callback
 * @private
 */
const _onClickPagination = (event, callback) => {
  event.preventDefault();

  const url = new URL(event.currentTarget.href);
  const params = url.pathname.split('/');
  const data = getQueryParams(url);

  // eslint-disable-next-line no-use-before-define
  callback(params[3], CATEGORY[params[3]], data.page);
};

/**
 * @param {string} selector
 * @param {function} callback
 */
export const enablePagination = (selector, callback) => {
  if (document.querySelector('.pagerfanta-v3')) {
    Pagination();
  }

  const selectorResults = document.querySelector(selector);
  const pagination = selectorResults?.querySelector('.pagerfanta-v2') || selectorResults?.querySelector('.pagerfanta-v3');

  if (!pagination) {
    return;
  }

  [].forEach.call(pagination.querySelectorAll('a'), (element) => {
    element.addEventListener('click', (event) => _onClickPagination(event, callback), false);
  });
};
